import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useGetQualificationDisplayLabel } from "@src/appV2/Qualifications/api/useGetQualificationDisplayLabel";
import { PageHeaderTopRow } from "@src/appV2/redesign/components/PageHeaderTopRow";
import { getGradientByTimeSlot } from "@src/appV2/redesign/Shift/getGradientByTimeSlot";

interface BookingRequestDetailsSheetoHeaderProps {
  qualificationName: string;
  timeSlot: TimeSlot;
  onClickBack: () => void;
}

export function BookingRequestDetailsSheetHeader(props: BookingRequestDetailsSheetoHeaderProps) {
  const { qualificationName, timeSlot, onClickBack } = props;

  const { data: qualificationText } = useGetQualificationDisplayLabel(qualificationName);

  return (
    <Stack
      alignItems="space-between"
      pt={5}
      pb={8}
      sx={(theme) => ({
        transition: theme.transitions.create(["background"]),
        background: getGradientByTimeSlot({ timeSlot, theme }),
      })}
      spacing={4}
    >
      <PageHeaderTopRow onClickBack={onClickBack} />
      <Stack px={7} spacing={4}>
        <Text>{qualificationText}</Text>
        <Text variant="h3">This shift is pending workplace approval</Text>
      </Stack>
    </Stack>
  );
}
