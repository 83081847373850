import { useModalState } from "@clipboard-health/ui-react";

import { Button } from "../../components/Button";
import { CancelBookingRequestSheet } from "./CancelBookingRequestSheet";

interface CancelBookingRequestButtonProps {
  bookingRequestId: string;
  workplaceId: string;
}

export function CancelBookingRequestButton(props: CancelBookingRequestButtonProps) {
  const { bookingRequestId, workplaceId } = props;
  const modalState = useModalState();

  return (
    <>
      <Button
        variant="outlined"
        sx={{ color: (theme) => theme.palette.error.light }}
        onClick={modalState.openModal}
      >
        Cancel Request
      </Button>
      <CancelBookingRequestSheet
        modalState={modalState}
        bookingRequestId={bookingRequestId}
        workplaceId={workplaceId}
      />
    </>
  );
}
