import { getTheme } from "@clipboard-health/ui-theme";
import { Alert, ThemeProvider } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

import { PageWrapper } from "../../components/PageWrapper";
import { StickyScrollPageHeader } from "../../components/StickyScrollPageHeader";
import { PageContent } from "../components/PageContent";
import { useGetBookingRequests } from "./api/useGetBookingRequests";
import { BookingRequestsList } from "./BookingRequestsList";
import { BookingRequestsEmptyPageState } from "./EmptyPageState";
import { BookingRequestDetailsLoadingPageState } from "./LoadingPageState";
import { BookingRequestStatus } from "./types";

export function BookingRequestsPage() {
  const scrollRef = useRef<HTMLDivElement>(null);

  const worker = useDefinedWorker();
  const { data, isLoading, isError, isSuccess, refetch } = useGetBookingRequests({
    filter: { status: { eq: BookingRequestStatus.PENDING }, "worker.id": { eq: worker.userId } },
    include: "shift,worker,offer",
  });

  const history = useHistory();

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <StickyScrollPageHeader
          title="My Booking Requests"
          scrollContainerRef={scrollRef}
          onClickBack={() => {
            history.push(`${RootPaths.HOME}/${MY_SHIFTS_PATH}`);
          }}
        />
        <PageContent ref={scrollRef}>
          <PullToRefresh
            onRefresh={async () => {
              await refetch();
            }}
          />
          {isLoading && <BookingRequestDetailsLoadingPageState />}
          {isError && <Alert severity="error">Error loading sent home requests.</Alert>}
          {isSuccess &&
            (data.data.length > 0 ? (
              <BookingRequestsList bookingRequests={data.data} included={data.included} />
            ) : (
              <BookingRequestsEmptyPageState />
            ))}
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
