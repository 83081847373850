import { CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { ShiftBottomSheetBookabilityCriteriaCard } from "@src/appV2/redesign/Shift/BottomSheet/BookabilityCriteriaCard";
import { ShiftBottomSheetCardsWrapper } from "@src/appV2/redesign/Shift/BottomSheet/CardsWrapper";
import { formatTime } from "@src/appV2/redesign/utils/formatTime";
import { parseISO } from "date-fns";

interface BookingRequestDetailsSheetContentProps {
  shiftStart: string;
  shiftEnd: string;
  workplaceName: string;
  workplaceTimezone: string;
}

export function BookingRequestDetailsSheetContent(props: BookingRequestDetailsSheetContentProps) {
  const { shiftStart, shiftEnd, workplaceName, workplaceTimezone } = props;

  return (
    <Stack spacing={8} alignItems="flex-start">
      <ShiftBottomSheetCardsWrapper>
        <ShiftBottomSheetBookabilityCriteriaCard
          intent="neutral"
          title={`${workplaceName} must approve all shift bookings. If you are approved, you will receive a notification, and will see this shift under My Bookings`}
          description=""
          illustrationType="clock"
        />
      </ShiftBottomSheetCardsWrapper>
      <Stack spacing={4} direction="row" alignItems="center" px={5} pb={1}>
        <CbhIcon type="sunset-filled" />
        <Text variant="h6">
          {formatTime(parseISO(shiftStart), workplaceTimezone)} -{" "}
          {formatTime(parseISO(shiftEnd), workplaceTimezone)}
        </Text>
      </Stack>
    </Stack>
  );
}
