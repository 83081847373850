import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useCheckIfWorkplaceHasBookingRequestsEnabled() {
  const workplaceConfig = useCbhFlag(CbhFeatureFlag.BOOKING_REQUESTS_WORKPLACE_CONFIG, {
    defaultValue: {
      workplaceIds: [],
      enabled: false,
    },
  });

  return (workplaceId: string) =>
    workplaceConfig.enabled && workplaceConfig.workplaceIds.includes(workplaceId);
}
