import {
  type BookingRequest,
  type BookingRequestIncluded,
  type BookingRequestIncludedOffer,
  type BookingRequestIncludedShift,
  type BookingRequestIncludedWorker,
  type BookingRequestIncludedWorkplace,
} from "../types";

export function extractIncludedEntitiesFromBookingRequests(
  bookingRequest?: BookingRequest,
  included?: BookingRequestIncluded
) {
  const includedWorker = included?.find(
    (includedEntity) =>
      includedEntity.type === "worker" &&
      includedEntity.id === bookingRequest?.relationships.worker?.data.id
  ) as BookingRequestIncludedWorker | undefined;
  const includedOffer = included?.find(
    (includedEntity) =>
      includedEntity.type === "offer" &&
      includedEntity.id === bookingRequest?.relationships.offer?.data.id
  ) as BookingRequestIncludedOffer | undefined;
  const includedShift = included?.find(
    (includedEntity) =>
      includedEntity.type === "shift" &&
      includedEntity.id === bookingRequest?.relationships.shift?.data.id
  ) as BookingRequestIncludedShift | undefined;

  const includedWorkplace = included?.find(
    (includedEntity) =>
      includedEntity.type === "workplace" &&
      includedEntity.id === includedShift?.relationships.workplace?.data.id
  ) as BookingRequestIncludedWorkplace | undefined;

  return { includedOffer, includedShift, includedWorkplace, includedWorker };
}
