import { getTheme } from "@clipboard-health/ui-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ModalRoute } from "@src/appV2/redesign/Navigation/ModalRoute";
import { ModalTransitionSwitch } from "@src/appV2/redesign/Navigation/ModalTransitionSwitch";

import { BOOKING_REQUEST_DETAILS_FULL_PATH, BOOKING_REQUESTS_FULL_PATH } from "../path";
import { BookingRequestDetailsSheet } from "./BookingRequestDetails/DetailsSheet";

export function BookingRequestsModalRoutes() {
  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <ModalTransitionSwitch>
        <ModalRoute
          shouldReplacePathOnBack
          closeModalPath={BOOKING_REQUESTS_FULL_PATH}
          path={BOOKING_REQUEST_DETAILS_FULL_PATH}
          render={({ modalState }) => {
            return (
              <BookingRequestDetailsSheet modalState={modalState} onClose={modalState.closeModal} />
            );
          }}
        />
      </ModalTransitionSwitch>
    </ThemeProvider>
  );
}
