import { PillSkeleton } from "@clipboard-health/ui-components";
import { Box, CardContent, Paper, Skeleton, Stack } from "@mui/material";
import { PageHeaderTopRow } from "@src/appV2/redesign/components/PageHeaderTopRow";
import { ShiftBottomSheetCardsWrapper } from "@src/appV2/redesign/Shift/BottomSheet/CardsWrapper";
import { ShiftBottomSheetInnerCard } from "@src/appV2/redesign/Shift/BottomSheet/InnerCard";

export function BookingRequestDetailsSheetSkeleton() {
  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack alignItems="space-between" pt={5} spacing={4}>
        <PageHeaderTopRow />
        <Stack px={7}>
          <Skeleton variant="text" width="30%" height="2rem" />
          <Skeleton variant="text" width="80%" height="6rem" />
        </Stack>
      </Stack>
      <Stack spacing={8}>
        <ShiftBottomSheetCardsWrapper>
          <ShiftBottomSheetInnerCard>
            <CardContent
              sx={{
                padding: 6,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 7,
              }}
            >
              <Skeleton sx={{ height: "100%", width: "100%" }} />
            </CardContent>
          </ShiftBottomSheetInnerCard>
        </ShiftBottomSheetCardsWrapper>
        <Stack spacing={4} direction="row" alignItems="center" px={5} pb={1}>
          <Skeleton variant="text" width="100%" height="2rem" />
        </Stack>
      </Stack>

      <Paper elevation={3} sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}>
        <Stack width="100%" p={5} pb={10} spacing={6} alignItems="center">
          <Skeleton variant="text" width="100%" height="2rem" />
          <PillSkeleton width="100%" height="3rem" />
        </Stack>
      </Paper>
    </Box>
  );
}
