import { Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function BookingRequestsEmptyPageState() {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={8} flexGrow={1}>
      <Illustration type="browse-shifts" />
      <Text variant="body1">You don&apos;t have any pending booking requests.</Text>
    </Stack>
  );
}
