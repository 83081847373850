import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

import {
  type BookingRequestsResponse,
  bookingRequestsResponseSchema,
  bookingRequestStatusSchema,
} from "../types";
import { BOOKING_REQUESTS_URL } from "./constants";
import { useWorkerHasBookingRequestsEnabled } from "./useWorkerHasBookingRequestsEnabled";

const bookingRequestParamsSchema = z.object({
  filter: z.object({
    status: z.object({ eq: bookingRequestStatusSchema }),
    "shift.id": z
      .object({
        eq: z.string(),
      })
      .optional(),

    "shift.start": z
      .object({
        gte: z.string().optional(),
        lte: z.string().optional(),
      })
      .optional(),
    "worker.id": z.object({ eq: z.string() }).optional(),
    "workplace.id": z.object({ eq: z.string() }).optional(),
  }),
  include: z.string().optional(),
  sort: z.string().optional(),
  fields: z
    .object({
      "booking-request": z.literal("status").optional(),
      shift: z.string().optional(),
      worker: z.string().optional(),
      offer: z.string().optional(),
    })
    .optional(),
  page: z
    .object({
      size: z.number().optional(),
      cursor: z.string().optional(),
    })
    .optional(),
});
type BookingRequestsParams = z.infer<typeof bookingRequestParamsSchema>;

export function useGetBookingRequests(
  params: BookingRequestsParams,
  options: UseGetQueryOptions<BookingRequestsResponse> = {}
) {
  const workerHasBookingRequestsEnabled = useWorkerHasBookingRequestsEnabled();

  return useGetQuery({
    url: BOOKING_REQUESTS_URL,
    queryParams: params,
    requestSchema: bookingRequestParamsSchema,
    responseSchema: bookingRequestsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_BOOKING_REQUESTS_FAILURE,
    },
    ...options,
    enabled: workerHasBookingRequestsEnabled && (options.enabled ?? true),
  });
}
