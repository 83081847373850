import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { extractIncludedEntitiesFromBookingRequests } from "./api/extractIncludedEntitiesFromBookingRequests";
import { BookingRequestShiftCard } from "./ShiftCard";
import { type BookingRequest, type BookingRequestIncluded } from "./types";

interface BookingRequestsListProps {
  bookingRequests: BookingRequest[];
  included: BookingRequestIncluded;
}
export function BookingRequestsList(props: BookingRequestsListProps) {
  const { bookingRequests, included } = props;

  return (
    <Stack spacing={5}>
      {bookingRequests.map((bookingRequest) => {
        const { includedShift, includedWorkplace, includedOffer } =
          extractIncludedEntitiesFromBookingRequests(bookingRequest, included);

        if (
          !isDefined(includedShift) ||
          !isDefined(includedWorkplace) ||
          !isDefined(includedOffer)
        ) {
          return null;
        }

        return (
          <BookingRequestShiftCard
            key={bookingRequest.id}
            bookingRequest={bookingRequest}
            shift={includedShift}
            workplace={includedWorkplace}
            offer={includedOffer}
          />
        );
      })}
    </Stack>
  );
}
