import { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { BottomSheet } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useParams } from "react-router-dom";

import { extractIncludedEntitiesFromBookingRequests } from "../api/extractIncludedEntitiesFromBookingRequests";
import { useGetBookingRequests } from "../api/useGetBookingRequests";
import { BookingRequestStatus } from "../types";
import { BookingRequestDetailsSheetContent } from "./DetailsSheetContent";
import { BookingRequestDetailsSheetFooter } from "./DetailsSheetFooter";
import { BookingRequestDetailsSheetHeader } from "./DetailsSheetHeader";
import { BookingRequestDetailsSheetSkeleton } from "./DetailsSheetSkeleton";

interface BookingRequestDetailsSheetProps {
  modalState: UseModalState;
  onClose: () => void;
}

export function BookingRequestDetailsSheet(props: BookingRequestDetailsSheetProps) {
  const { modalState, onClose } = props;

  const worker = useDefinedWorker();
  const { bookingRequestId } = useParams<{ bookingRequestId: string }>();

  const { data, isLoading, isSuccess } = useGetBookingRequests(
    {
      filter: { status: { eq: BookingRequestStatus.PENDING }, "worker.id": { eq: worker.userId } },
      include: "shift,worker,offer",
    },
    { enabled: isDefined(worker.userId) }
  );

  const bookingRequest = data?.data.find((request) => request.id === bookingRequestId);
  const { includedShift, includedWorkplace, includedOffer } =
    extractIncludedEntitiesFromBookingRequests(bookingRequest, data?.included);

  return (
    <BottomSheet modalState={modalState}>
      <Stack>
        {isLoading && <BookingRequestDetailsSheetSkeleton />}
        {isSuccess &&
          isDefined(bookingRequest) &&
          isDefined(includedShift) &&
          isDefined(includedOffer) &&
          isDefined(includedWorkplace) && (
            <Stack>
              <BookingRequestDetailsSheetHeader
                qualificationName={includedShift.attributes.qualification}
                timeSlot={(includedShift.attributes.name as TimeSlot) ?? TimeSlot.AM}
                onClickBack={onClose}
              />
              <BookingRequestDetailsSheetContent
                shiftStart={includedShift.attributes.start}
                shiftEnd={includedShift.attributes.end}
                workplaceName={includedWorkplace?.attributes.name ?? ""}
                workplaceTimezone={includedWorkplace?.attributes.tmz ?? ""}
              />
              <BookingRequestDetailsSheetFooter
                hourlyPay={includedOffer.attributes.pay.value}
                shiftDuration={includedShift.attributes.time}
                bookingRequestId={bookingRequestId}
                workplaceId={includedWorkplace?.id ?? ""}
              />
            </Stack>
          )}
      </Stack>
    </BottomSheet>
  );
}
