import { post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { BOOKING_REQUESTS_URL } from "./constants";

const createBookingRequestSchema = z.object({
  data: z.object({
    type: z.literal("booking-request"),
    relationships: z.object({
      shift: z.object({
        data: z.object({
          type: z.literal("shift"),
          id: z.string(),
        }),
      }),
      worker: z.object({
        data: z.object({
          type: z.literal("worker"),
          id: z.string(),
        }),
      }),
      offer: z.object({
        data: z.object({
          type: z.literal("offer"),
          id: z.string(),
        }),
      }),
    }),
  }),
});

const createBookingRequestResponseSchema = z.object({
  data: z.object({
    type: z.literal("booking-request"),
    id: z.string(),
    attributes: z.object({
      status: z.enum(["PENDING", "ACCEPTED", "CANCELLED", "EXPIRED"]),
    }),
    relationships: z.object({
      shift: z.object({
        data: z.object({
          type: z.literal("shift"),
          id: z.string(),
        }),
      }),
      worker: z.object({
        data: z.object({
          type: z.literal("worker"),
          id: z.string(),
        }),
      }),
      offer: z.object({
        data: z.object({
          type: z.literal("offer"),
          id: z.string(),
        }),
      }),
    }),
  }),
});

export type CreateBookingRequestRequest = z.infer<typeof createBookingRequestSchema>;
export type CreateBookingRequestResponse = z.infer<typeof createBookingRequestResponseSchema>;

export function useCreateBookingRequest(
  options: UseMutationOptions<
    CreateBookingRequestResponse,
    AxiosError,
    { shiftId: string; offerId?: string; workerId: string }
  > = {}
): UseMutationResult<
  CreateBookingRequestResponse,
  AxiosError,
  { shiftId: string; offerId?: string; workerId: string }
> {
  return useMutation({
    mutationFn: async ({
      shiftId,
      offerId,
      workerId,
    }: {
      shiftId: string;
      offerId?: string;
      workerId: string;
    }) => {
      if (!offerId) {
        throw new Error("Shift offer ID is required for booking requests");
      }

      const requestData: CreateBookingRequestRequest = {
        data: {
          type: "booking-request",
          relationships: {
            shift: {
              data: {
                type: "shift",
                id: shiftId,
              },
            },
            worker: {
              data: {
                type: "worker",
                id: workerId,
              },
            },
            offer: {
              data: {
                type: "offer",
                id: offerId,
              },
            },
          },
        },
      };

      const response = await post({
        url: BOOKING_REQUESTS_URL,
        data: requestData,
        requestSchema: createBookingRequestSchema,
        responseSchema: createBookingRequestResponseSchema,
      });

      return response.data;
    },
    ...options,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_BOOKING_REQUEST_FAILURE,
      ...options.meta,
    },
  });
}
