import { Stack } from "@mui/material";
import { WorkerShiftCardSkeleton } from "@src/appV2/redesign/Shift/WorkerShift/CardSkeleton";

export function BookingRequestDetailsLoadingPageState() {
  return (
    <Stack spacing={4}>
      <WorkerShiftCardSkeleton />
      <WorkerShiftCardSkeleton />
    </Stack>
  );
}
