import { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Card } from "@clipboard-health/ui-components";
import { InternalLink } from "@clipboard-health/ui-react";
import { CardActionArea } from "@mui/material";
import { useGetQualificationDisplayLabel } from "@src/appV2/Qualifications/api/useGetQualificationDisplayLabel";
import { shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { generatePath } from "react-router-dom";

import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { WorkerShiftCard } from "../../Shift/WorkerShift/Card";
import { BOOKING_REQUEST_DETAILS_FULL_PATH } from "../path";
import {
  type BookingRequest,
  type BookingRequestIncludedOffer,
  type BookingRequestIncludedShift,
  type BookingRequestIncludedWorkplace,
} from "./types";

interface BookingRequestShiftCardProps {
  shift: BookingRequestIncludedShift;
  bookingRequest: BookingRequest;
  workplace: BookingRequestIncludedWorkplace;
  offer: BookingRequestIncludedOffer;
}

export function BookingRequestShiftCard(props: BookingRequestShiftCardProps) {
  const { shift, bookingRequest, workplace, offer } = props;

  const payBreakdown = getFormattedShiftPayBreakdown(
    offer.attributes.pay.value,
    shift.attributes.time * offer.attributes.pay.value,
    shift.attributes.time * offer.attributes.pay.value
  );
  const href = generatePath(BOOKING_REQUEST_DETAILS_FULL_PATH, {
    bookingRequestId: bookingRequest.id,
  });

  const { data: qualificationText } = useGetQualificationDisplayLabel(
    shift.attributes.qualification
  );

  return (
    <Card outlined variant="quaternary">
      <CardActionArea component={InternalLink} to={href}>
        <WorkerShiftCard
          href={href}
          workplaceName={workplace.attributes.name}
          workplaceTimezone={workplace.attributes.tmz ?? "America/Los_Angeles"}
          shiftTimeSlot={shiftPeriodToTimeSlot[shift.attributes.name] ?? TimeSlot.AM}
          shiftStart={shift.attributes.start}
          shiftEnd={shift.attributes.end}
          shiftQualificationName={qualificationText}
          shiftHourlyPay={payBreakdown?.hourlyPay}
          shiftTotalPay={payBreakdown?.totalPay}
          shiftWorkDurationInHours={shift.attributes.time}
        />
      </CardActionArea>
    </Card>
  );
}
