import { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { z } from "zod";

export enum BookingRequestStatus {
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
}
export const bookingRequestStatusSchema = z.nativeEnum(BookingRequestStatus);

export const bookingRequestSchema = z.object({
  type: z.literal("booking-request"),
  id: z.string(),
  attributes: z.object({
    status: z.nativeEnum(BookingRequestStatus),
  }),
  relationships: z.object({
    shift: z
      .object({
        data: z.object({
          type: z.literal("shift"),
          id: z.string(),
        }),
      })
      .optional(),
    worker: z
      .object({
        data: z.object({
          type: z.literal("worker"),
          id: z.string(),
        }),
      })
      .optional(),
    offer: z
      .object({
        data: z.object({
          type: z.literal("offer"),
          id: z.string(),
        }),
      })
      .optional(),
  }),
});
export type BookingRequest = z.infer<typeof bookingRequestSchema>;

export const bookingRequestIncludedShiftSchema = z.object({
  type: z.literal("shift"),
  id: z.string(),
  attributes: z.object({
    start: z.string(),
    end: z.string(),
    qualification: z.string(),
    time: z.number(),
    name: z.nativeEnum(TimeSlot),
  }),
  relationships: z.object({
    workplace: z
      .object({
        data: z.object({
          type: z.literal("workplace"),
          id: z.string(),
        }),
      })
      .optional(),
  }),
});
export type BookingRequestIncludedShift = z.infer<typeof bookingRequestIncludedShiftSchema>;

export const bookingRequestIncludedWorkplaceSchema = z.object({
  type: z.literal("workplace"),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    distance: z.number(),
    tmz: z.string(),
  }),
});
export type BookingRequestIncludedWorkplace = z.infer<typeof bookingRequestIncludedWorkplaceSchema>;

export const bookingRequestIncludedWorkerSchema = z.object({
  type: z.literal("worker"),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    qualification: z.string(),
  }),
});
export type BookingRequestIncludedWorker = z.infer<typeof bookingRequestIncludedWorkerSchema>;

export const bookingRequestIncludedOfferSchema = z.object({
  type: z.literal("offer"),
  id: z.string(),
  attributes: z.object({
    pay: z.object({
      value: z.number(),
      currency: z.literal("USD"),
    }),
    expiresAt: z.string(),
  }),
});
export type BookingRequestIncludedOffer = z.infer<typeof bookingRequestIncludedOfferSchema>;

export const bookingRequestIncludedSchema = z
  .array(
    z.discriminatedUnion("type", [
      bookingRequestIncludedShiftSchema,
      bookingRequestIncludedWorkplaceSchema,
      bookingRequestIncludedWorkerSchema,
      bookingRequestIncludedOfferSchema,
    ])
  )
  .optional();
export type BookingRequestIncluded = z.infer<typeof bookingRequestIncludedSchema>;

export const bookingRequestsResponseSchema = z.object({
  data: z.array(bookingRequestSchema),
  included: bookingRequestIncludedSchema,
});
export type BookingRequestsResponse = z.infer<typeof bookingRequestsResponseSchema>;

export const deleteBookingRequestResponseSchema = z.object({
  data: bookingRequestSchema,
});
export type DeleteBookingRequestResponse = z.infer<typeof deleteBookingRequestResponseSchema>;
