import { Text } from "@clipboard-health/ui-react";
import { Paper, Stack } from "@mui/material";
import { HourlyPayLabel } from "@src/appV2/redesign/Pay/HourlyPayLabel";
import { getFormattedShiftPayBreakdown } from "@src/appV2/redesign/Shift/getFormattedShiftPayBreakdown";

import { CancelBookingRequestButton } from "../CancelBookingRequestButton";

interface BookingRequestDetailsSheetoFooterProps {
  hourlyPay: number;
  shiftDuration: number;
  bookingRequestId: string;
  workplaceId: string;
}

export function BookingRequestDetailsSheetFooter(props: BookingRequestDetailsSheetoFooterProps) {
  const { bookingRequestId, workplaceId, hourlyPay, shiftDuration } = props;
  const shiftPayBreakdown = getFormattedShiftPayBreakdown(
    hourlyPay,
    shiftDuration * hourlyPay,
    shiftDuration * hourlyPay
  );

  return (
    <Paper elevation={3} sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}>
      <Stack p={5} pb={10} spacing={6}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text variant="h4">{shiftPayBreakdown?.totalPay}</Text>
          <HourlyPayLabel hourlyPay={shiftPayBreakdown?.hourlyPay} size="medium" />
        </Stack>
        <CancelBookingRequestButton bookingRequestId={bookingRequestId} workplaceId={workplaceId} />
      </Stack>
    </Paper>
  );
}
