import { remove } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  bookingRequestSchema,
  type DeleteBookingRequestResponse,
  deleteBookingRequestResponseSchema,
} from "../types";
import { BOOKING_REQUESTS_URL, DELETE_BOOKING_REQUEST_URL } from "./constants";

export const deleteBookingRequestBodySchema = z.object({
  data: bookingRequestSchema.omit({ relationships: true }),
});
export type DeleteBookingRequestBody = z.infer<typeof deleteBookingRequestBodySchema>;

interface DeleteBookingRequestVariables {
  id: string;
}

export function useDeleteBookingRequest(
  options: UseMutationOptions<
    DeleteBookingRequestResponse,
    unknown,
    DeleteBookingRequestVariables
  > = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (variables) => {
      const resp = await remove({
        url: DELETE_BOOKING_REQUEST_URL.replace(":bookingRequestId", variables.id),
        responseSchema: deleteBookingRequestResponseSchema,
      });

      return resp.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.DELETE_BOOKING_REQUEST_FAILURE,
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            (query.queryKey?.[0] as string | undefined)?.includes(BOOKING_REQUESTS_URL) ?? false
          );
        },
      });
    },
  });
}
