import { BottomSheet } from "@clipboard-health/ui-components";
import { LoadingButton, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { BOOKING_REQUESTS_FULL_PATH } from "../path";
import { useDeleteBookingRequest } from "./api/useDeleteBookingRequest";

interface CancelBookingRequestSheetProps {
  bookingRequestId: string;
  workplaceId: string;
  modalState: UseModalState;
}

export function CancelBookingRequestSheet(props: CancelBookingRequestSheetProps) {
  const { bookingRequestId, workplaceId, modalState } = props;

  const { mutateAsync, isLoading } = useDeleteBookingRequest();
  const { showSuccessToast, showErrorToast } = useToast();

  const history = useHistory();

  return (
    <BottomSheet modalState={modalState}>
      <Stack spacing={8} padding={8}>
        <IconButton
          sx={{ alignSelf: "flex-end" }}
          size="small"
          iconType="close"
          variant="outlined"
          onClick={modalState.closeModal}
        />
        <Stack alignItems="center" textAlign="center" spacing={3}>
          <Title component="h3" sx={{ textWrap: "balance" }}>
            Cancel booking request
          </Title>
          <Text sx={{ textWrap: "balance" }}>
            Are you sure you want to cancel this request? Doing so will remove you from the list of
            professionals the workplace can accept for the shift. Your Clipboard Score will not be
            impacted.
          </Text>
        </Stack>
        <LoadingButton
          variant="contained"
          isLoading={isLoading}
          onClick={async () => {
            try {
              const deletedBookingRequest = await mutateAsync({ id: bookingRequestId });

              logEvent(APP_V2_USER_EVENTS.BOOKING_REQUEST_CANCELED, {
                bookingRequestId,
                workplaceId,
                workerId: deletedBookingRequest.data.relationships.worker?.data.id,
                shiftId: deletedBookingRequest.data.relationships.shift?.data.id,
              });
              showSuccessToast("Booking request successfully canceled.");
              history.replace(BOOKING_REQUESTS_FULL_PATH);
            } catch {
              showErrorToast("Error canceling booking request!");
            }
          }}
        >
          Cancel Request
        </LoadingButton>
      </Stack>
    </BottomSheet>
  );
}
